
<template>
  <div class="auth">
    <div class="modal">
      <div class="title">绑定账号</div>
      <a-form :form="form" class="form" @submit="handleSubmit">
        <a-form-item label="Api Key" has-feedback class="item">
          <a-input
            v-decorator="[
              'api_key',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your  Api key!',
                  },
                ],
              },
            ]"
            type="text"
          />
        </a-form-item>
        <a-form-item label="Api Secret" has-feedback class="item">
          <a-input
            v-decorator="[
              'api_secret',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your  Api Secret!',
                  },

                ],
              },
            ]"
            type="text"
          />
        </a-form-item>
        <a-form-item class="item">
          <a-button class="button" type="primary" html-type="submit">绑定 </a-button>
        </a-form-item>
      </a-form>
      <div />
    </div>

  </div>
</template>

<script>

import Client, {
  shared, Redirect
} from '@shoplinedev/appbridge'
import { bindUser } from '@/api/index'

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: 'changePasswordForm' }),
      mail: '',
      password: ''
    }
  },
  created() {
  },
  methods: {
    redirectToShopline() {
      const params = new URLSearchParams(window.location.search)
      // 获取参数值
      const appkey = params.get('appkey')
      const host = shared.getHost()
      const app = Client.createApp({
        appKey: appkey,
        host
      })
      const redirect = Redirect.create(app)
      const redirectUri = `/settings/payments/appInstall?appKey=${appkey}`
      redirect.routerTo(redirectUri)
    },
    getParams() {
      const params = new URLSearchParams(window.location.search)
      // 获取参数值
      const appkey = params.get('appkey')
      const handle = params.get('handle')
      const sign = params.get('sign')
      const timestamp = params.get('timestamp')
      const code = params.get('code')
      const lang = params.get('lang')
      return {
        code, appkey, handle, sign, timestamp, lang
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const { api_key, api_secret } = values
          // { code, appkey, handle, sign, timestamp}
          const query = this.getParams()
          const params = {
            api_key,
            api_secret,
            ...query
          }
          console.log(params)
          bindUser(params)
            .then((res) => {
              this.$message.success(res.msg)
              console.log(res.code)
              this.redirectToShopline()
            }).catch((bind_err) => {
              console.log(bind_err)
            })
        }
      })
    }
  }

}

</script>

<style lang="less" scoped>
.auth {
  padding: 0;
  margin: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    width: 970px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title{
      width: 100%;
      text-align: center;
      margin-top: 30px;
      font-size: 16px;
    }
    .form{
      // margin-top: 20px;
      width: 100%;
      .item{
        margin-top: 10px;
      }
    }
    .top {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          width: 60px;
          height: 60px;
          background: #0045ff;
        }

        .text {
          font-size: 14px;

        }
      }
    }

    .button {
      width: 100%;
      color: #fff;
      display: flex;
      align-items: center;
      // height: 48px;
      line-height: 28px;
      justify-content: center;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 2px;
      font-weight: 700;
      border-radius: 60px;
      // margin-top: 10px;
      background: #0045ff;
      border: 1px solid #0045ff;

    }
  }
}
</style>
