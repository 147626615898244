var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth"},[_c('div',{staticClass:"modal"},[_c('div',{staticClass:"title"},[_vm._v("绑定账号")]),_c('a-form',{staticClass:"form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"item",attrs:{"label":"Api Key","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'api_key',
            {
              rules: [
                {
                  required: true,
                  message: 'Please enter your  Api key!',
                },
              ],
            },
          ]),expression:"[\n            'api_key',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please enter your  Api key!',\n                },\n              ],\n            },\n          ]"}],attrs:{"type":"text"}})],1),_c('a-form-item',{staticClass:"item",attrs:{"label":"Api Secret","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'api_secret',
            {
              rules: [
                {
                  required: true,
                  message: 'Please enter your  Api Secret!',
                },

              ],
            },
          ]),expression:"[\n            'api_secret',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please enter your  Api Secret!',\n                },\n\n              ],\n            },\n          ]"}],attrs:{"type":"text"}})],1),_c('a-form-item',{staticClass:"item"},[_c('a-button',{staticClass:"button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("绑定 ")])],1)],1),_c('div')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }